<template>
  <div>
    <div class="section">
      <div class="txt">
        <div class="point"></div>
        <p>数据每半小时获取一次，因第三方平台规则限制，数据更新可能会有延迟</p>
      </div>
      <div class="txt">
        <div class="point"></div>
        <p>账号数据属于公司隐私信息，考虑到安全性且从第三方平台获取的数据也受到平台规则限制，数据的获取需手动前往第三方平台进行授权等操作</p>
      </div>
    </div>

    <div class="section">
      <div class="title">获取数据指引</div>
      <div class="txt">
          <div class="point"></div>
          <p>点击列表中某一账号的【授权】操作，打开手机抖音-首页中的扫一扫进行登录授权，即通过授权可获取星图指标相关数据</p>
        </div>

      <div class="tool-step">
        <img :src="toolDownStep"/>
        <div class="txt mid-txt">
          <div class="point"></div>
          <p>更多字段获取可借助小工具，点击<span class="download-mark" @click="downTool">「下载」</span>获取压缩包后解压并运行即可</p>
        </div>
      </div>

      <div class="step-footer">
        <div class="step step-one">STEP1:下载工具包</div>
        <div class="step step-two">STEP2:解压并双击运行</div>
        <div class="step step-three">STEP3:选择达人入口进行登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGrabToolDownloadUrl } from "@/api/xt/index";

export default {
  data() {
    return {
      qrCodeUrl: require("@/assets/img/xt/dy_qr_code.png"),
      rightArrow: require("@/assets/img/xt/right_arrow.png"),
      numericOne: require("@/assets/img/xt/notice_numeric_one.png"),
      numericTwo: require("@/assets/img/xt/notice_numeric_two.png"),
      toolDownStep: require("@/assets/img/xt/tool_down_step.png"),
      downUrl: '', // 工具下载地址
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getToolDownUrl();
    },
    getToolDownUrl() {
      getGrabToolDownloadUrl().then(response => {
        const { code, msg, data } = response;
        if (Number(code) !== 0) {
          this.$message.error(msg || '工具下载地址获取失败！');
          return ;
        }
        this.downUrl = data?.download_website ?? '';
      }).catch(error => {

      });
    },
    downTool() {
      if (!this.downUrl) {
        this.$message.error('下载地址为空！');
        return ;
      }

      window.open(this.downUrl);
    },
  }
}
</script>

<style lang="scss" scoped>
  .section {
    .title {
      color: #363636;
      font-weight: bold;
      font-size: 16px;
    }

    .txt {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #363636;
      margin-bottom: 12px;
      margin-right: 14px;
      margin-top: 20px;
      &:last-child {
        margin-bottom: 27px;
      }
      
      .point {
        display: block;
        width: 6px;
        height: 6px;
        background-color: #2B6BFF;
        border-radius: 50%;
        margin-right: 10px;
      }

      p {
        flex: 1;
      }
    }

    .txt:nth-child(2) {
      position: relative;
      height: 43px;
      & > .point {
        position: absolute;
        left: 0;
        top: 8px;
      }

      & > p {
        position: absolute;
        left: 16px;
      }
    }
  }

  .tool-step {
    display: block;
    width: 90%;
    height: 382px;
    position: relative;
    margin: 20px auto;
    & > img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .mid-txt {
      display: block;
      width: 100%;
      position: absolute !important;
      top: 172px;
      left: -35px;
    }
  }

  .step-footer {
    margin-top: 15px;
    position: relative;
    height: 20px;
    line-height: 20px;
    width: 100%;
    .step {
      position: absolute;
      bottom: 0;
    }

    .step-one {
      left: 44px;
    }

    .step-two {
      left: 269px;
    }

    .step-three {
      right: 0;
    }
  }
  
  ::v-deep .guide-img {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 30px 0;
    .common {
      width: 170px;
      height: 180px;
      background-color: #F7F8FA;
      border-radius: 8px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .numeric {
        font-style: italic;
        position: absolute;
        left: 0;
        top: -15.5px;
        display: block;
      }
    }

    .user {
      .user-icon {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: #E5EBF8;
        text-align: center;
        line-height: 71px;
        .md-icon-oauth {
          font-size: 45px;
          color: #2B6BFF;
        }
      }

      p.auth {
        color: #3C3C3C;
        font-weight: 450;
        margin-top: 10px;
        font-size: 16px;
      }
    }

    .qr-code {
      .qr-code-img {
        display: block;
        width: 110px;
        height: 110px;
        overflow: hidden;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .scan-tip {
        margin-top: 7px;
        text-align: center;
        .mark {
          color: #FD5E7A;
        }
      }
    }
  }

  .txt {
    .download-mark {
      color: #3A75FF;
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>