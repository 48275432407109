<template>
  <div id="vueDiv" class="main" :style="'height:' + windowHeight + 'px'">
    <div class="leftMain">
      <div class="userView">
        <el-dropdown trigger="click">
          <img :src="avatar != '' ? avatar : VUE_APP_API_BASE_URL + '/static/admin/img/userImg.png'">
          <div>{{ name }}</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout()">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <template v-for="(item, index) in leftMenuList">
        <div :class="'main-left-menu' + (menuType == item.id ? ' main-left-active' : '')" @click='selectMenu(index)'
          :key="index"
          v-if="item.position == 1 && (!item['has_submenu'] || (item['submenu'] && item['submenu'].length > 0))">
          <div>
            <svg-icon :icon-class="item.new_icon"
              :class="'main-left-menu-icon' + (menuType == item.id ? ' main-left-active' : '')" />
          </div>
          <div class="main-left-menu-title">
            {{ item.title }}
          </div>
        </div>
      </template>
      <div style="position: fixed;bottom:8px">
        <template v-for="(item, index) in leftMenuList">
          <div :class="'main-left-menu' + (menuType == item.id ? ' main-left-active' : '')" @click='selectMenu(index)'
            :key="index"
            v-if="item.position == 2 && (!item['has_submenu'] || (item['submenu'] && item['submenu'].length > 0))">
            <div>
              <svg-icon :icon-class="item.new_icon"
                :class="'main-left-menu-icon' + (menuType == item.id ? ' main-left-active' : '')" />
            </div>
            <div class="main-left-menu-title">
              {{ item.title }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="rightMain" :style="'width:'+(windowWidth-80)+'px'"> -->
    <div class="rightMain">
      <div class="topMenu clearfix" v-if="topMmenuList.length > 0">
        <template v-for='(item, index) in topMmenuList'>
          <div v-if="!item['has_submenu'] || (item['submenu'] && item['submenu'].length > 0)" :key="index"
            :class="(topMenuSelectIndex) == index ? 'selectMenuItemDiv' : 'menuItemDiv'" @click='selectTopMenu(index)'>
            {{ item.title }}
          </div>
        </template>
        <div class="tip" @click="openXtTipDialog" v-show="$route.path == '/xt/account'">数据获取说明<i class="el-icon-warning-outline warning"></i></div>
      </div>
      <div class="contentDiv" :style="(topMmenuList.length > 0 ? '' : 'margin-top: 0px')">
        <div :id="sliderHidden ? 'hiddenElem' : 'noHiddenElem'" class="contentMenu" v-if="subMenuList.length > 0"
          :style="{ height: (windowHeight - 90) + 'px', }">
          <div style="cursor: pointer" v-for="(item, index) in subMenuList" :key="item.id"
            :class="leftContentMenuType == item.id ? 'selectContentMenuItem contentMenuItem' : 'contentMenuItem'"
            @click='selectContentMenu(index)'>
            <img v-if="item.new_icon" :src="leftContentMenuType == item.id ? ('/static/admin/img/' + item.new_icon + '_1.png?t=2022021101') : '/static/admin/img/' +
              item.new_icon + '.png?t=2022021101'">
            {{ item.title }}
          </div>
        </div>
        <div class="slide-bar-left" v-on:click="hiddenSlideBar" v-if="subMenuList.length > 0">
          <!-- <i :class="'el-icon-d-arrow-' + (sliderHidden ? 'right' : 'left')"></i>
          <i :class="'el-icon-d-arrow-' + (sliderHidden ? 'right' : 'left')"></i>
          <i :class="'el-icon-d-arrow-' + (sliderHidden ? 'right' : 'left')"></i> -->
          <img style="width: 24px;height: 24px;" :class="sliderHidden?'':'flip'" :src="openAndCloseImg">
        </div>
        <div class="content" :class="subMenuList.length > 0 ? class_name : ''"
          :style="'height:' + (newHeight - (topMmenuList.length > 0 ? 90 : 10)) + 'px'">
          <router-view :key="key" />
        </div>
      </div>
      <div class="watermark-box">
        <span v-for="v in 100" :key="v">美哒&nbsp;{{ name }}{{ mobile_suffix }}</span>
      </div>
    </div>

    <el-dialog
      title="数据获取说明"
      v-if="xtTipDialog.visible"
      :visible.sync="xtTipDialog.visible"
      custom-class="xt-tip-dialog"
      @close="xtTipDialog.visible = false;"
      width="720px"
    >
      <XtTipDialog />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { check_detect } from '@/api/common'
import XtTipDialog from '@/views/xt/components/tip';

import $ from 'jquery'

export default {
  components: {
    XtTipDialog
  },
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      newHeight: document.documentElement.clientHeight,
      menuType: 1,//左边选中的菜单
      topMenuSelectIndex: 1,//头部选中的部门下标+1
      leftContentMenuType: 1,//左边内容子菜单
      VUE_APP_API_BASE_URL: '',
      ENV: '',
      topMmenuList: [],
      leftMenuList: [],
      subMenuList: [],
      mobile_suffix: '',
      check_detect_interval: null,
      class_name: 'limit-width',
      sliderHidden: false,
      xtTipDialog: {
        visible: false,
      },
      openAndCloseImg:require("@/assets/img/content_manage/openAndClose.png")
    }
  },
  computed: {
    ...mapGetters([
      'menus',
      'avatar',
      'name',
      'first_route_path',
      'dynamic_auth',
    ]),
    key() {
      return this.$route.path
    }
  },
  watch: {
    '$root.jumpToClipMenu': {
      handler() {
        if(this.$root.jumpToClipMenu === true) {
          console.log('$root.jumpToClipMenu: ', this.$root.jumpToClipMenu)
          console.log('topMmenuList: ', this.topMmenuList)
          for(let i=0; i < this.topMmenuList.length;i++) {
            if(this.topMmenuList[i]['name'] == 'contentManage/clipOrderSecondMenu' && this.topMmenuList[i].has_submenu === true) {
              for(let j=0; j < this.topMmenuList[i].submenu.length;j++) {
                if(this.topMmenuList[i].submenu[j].name == 'contentWorkOrder/clip_confirm_order') {
                  this.selectContentMenu(j)
                  return
                }
              }
            }
          }
        }
        // console.log('total:' + this.$root.totalScore)
        // this.score = this.$root.totalScore
      },
      deep: true
    },
  },
  mounted() {
    this.mobile_suffix = this.dynamic_auth.mobile_suffix
    this.zoomWindow()

    /*
      this.check_detect_interval = setInterval(function() {
        // 每隔两分钟检测一次版本信息
        check_detect().then(response => {
        }).catch(error => {})
      }, 1000 * 120)
      console.log('setInterval:' + this.check_detect_interval)*/

    // console.log('first_route_path', this.first_route_path)
    this.VUE_APP_API_BASE_URL = process.env.VUE_APP_API_BASE_URL
    this.ENV = process.env.NODE_ENV
    let leftMenuList = []
    let topMmenuList = []
    let subMenuList = []
    let menuType = null
    let topMenuSelectIndex = null
    let leftContentMenuType = null
    let route_path = this.$route.path
    leftMenuList = this.menus
    for (let k = 0; k < leftMenuList.length && menuType === null; k++) {
      if (route_path == '/' + leftMenuList[k]['name']) {
        menuType = leftMenuList[k]['id']
      }
      if (leftMenuList[k]['submenu']) {
        topMmenuList = leftMenuList[k]['submenu']
        for (let kk = 0; kk < topMmenuList.length && topMenuSelectIndex === null; kk++) {
          if (route_path == '/' + topMmenuList[kk]['name']) {
            menuType = leftMenuList[k]['id']
            topMenuSelectIndex = kk
          }
          if (topMmenuList[kk]['submenu']) {
            subMenuList = topMmenuList[kk]['submenu']
            for (let kkk = 0; kkk < subMenuList.length && leftContentMenuType === null; kkk++) {
              if (route_path == '/' + subMenuList[kkk]['name']) {
                menuType = leftMenuList[k]['id']
                topMenuSelectIndex = kk
                leftContentMenuType = subMenuList[kkk]['id']
              }
            }
          }
        }
      }
    }
    this.leftMenuList = leftMenuList
    this.topMmenuList = topMenuSelectIndex !== null ? topMmenuList : []
    this.subMenuList = leftContentMenuType !== null ? subMenuList : []
    this.menuType = menuType
    this.topMenuSelectIndex = topMenuSelectIndex
    this.leftContentMenuType = leftContentMenuType
  },
  beforeMount() {
    // console.log('beforeMount')
    window.addEventListener('resize', this.$_resizeHandler) // resize自适应缩放，当窗口调整时，自动缩放
  },
  beforeDestroy() {
    /*
    if(this.check_detect_interval !== null) {
      clearInterval(this.check_detect_interval)
      console.log('clearInterval:' + this.check_detect_interval)
    }
    window.removeEventListener('resize', this.$_resizeHandler)*/
  },
  methods: {
    openXtTipDialog() {
      this.xtTipDialog.visible = true;
    },
    hiddenSlideBar() {
      this.sliderHidden = !this.sliderHidden
    },
    zoomWindow() { // 缩放窗口
      let newRadio;
      if (this.windowWidth < 1600) {
        newRadio = 0.85
        this.$root.isUseZoom = true
      }
      else {
        newRadio = 1
        this.$root.isUseZoom = false
      }
      // console.log('newRadio', newRadio)
      // console.log(this.windowWidth, this.windowHeight, this.newHeight)
      let newWidth = this.windowWidth / newRadio
      let newHeight = this.windowHeight / newRadio
      this.newHeight = newHeight
      if (this.windowWidth < 1600) {
        this.class_name = 'small-width'
      }
      else {
        this.class_name = 'limit-width'
      }
      // console.log('newWH:', newWidth, newHeight)
      $('#app').css("width", newWidth)
      $('#app').css("height", newHeight)
      $('#vueDiv').css("width", newWidth)
      $('#vueDiv').css("height", newHeight)
      $('.rightMain').css('width', newWidth - 80)
      $('.rightMain').css('height', newHeight)
      $('body').css("width", newWidth)
      $('body').css("height", newHeight)
      // $('.content').css()
      // $('body').css('transform', 'scale('+newRadio+', '+newRadio+')')
      // $('body').css('transform-origin', 'left top 0')
      $('body').css('zoom', newRadio)
    },
    $_resizeHandler() {
      // console.log('in resize.!!')
      this.windowHeight = document.documentElement.clientHeight,
        this.windowWidth = document.documentElement.clientWidth,
        this.newHeight = document.documentElement.clientHeight,
        this.zoomWindow()
    },
    route_replace(path) {
      path = '/' + path
      if (path != this.$route.path) {
        this.$router.replace(path)
      }
    },
    logout() {
      this.$store.dispatch('user/logout').then((response) => {
        this.$message({
          message: response.msg,
          type: 'success'
        })
        window.location.reload()
      }).catch(error => {
      })
    },
    setLeftSubMenus(setCur = false) {
      // TODO
    },
    selectMenu: function (type) { //设置左边菜单
      if (this.leftMenuList[type]['new_icon'] === 'menu_crm') {
        if (this.ENV === 'development') {
          window.open("http://localhost:8081/#/talentAccount/index", '_blank')
        }
        else {
          window.open(this.VUE_APP_API_BASE_URL + "crm/#/talentAccount/index", '_blank')
        }
      } else if (this.leftMenuList[type]['new_icon'] === 'menu_chatai') {
        window.open(this.leftMenuList[type]['name'], '_blank')
      } else {
        let orig_type = this.menuType
        this.menuType = this.leftMenuList[type]['id']
        if (this.menuType != orig_type) {
          this.topMenuSelectIndex = this.leftContentMenuType = null;
        }
        this.topMmenuList = []
        this.subMenuList = []
        if (this.leftMenuList[type]['submenu']) {
          this.topMmenuList = this.leftMenuList[type]['submenu']
          this.selectTopMenu(0)
        }
        else {
          // this.$router.replace('/' + this.leftMenuList[type].name)
          this.route_replace(this.leftMenuList[type].name)
        }
      }
    }, selectTopMenu: function (index) { //设置头部菜单
      let origin_idnex = this.topMenuSelectIndex
      this.topMenuSelectIndex = index
      if (origin_idnex != this.topMenuSelectIndex) {
        this.leftContentMenuType = null
      }
      this.subMenuList = []
      if (this.topMmenuList[index]['submenu']) {
        this.subMenuList = this.topMmenuList[index]['submenu']
        if (this.topMmenuList[index]['name'] == 'approve/list/2') {
          let findIndex = this.subMenuList.findIndex(item => item.name == 'approve/list/2');
          if (findIndex !== -1) {
            this.selectContentMenu(findIndex);
          }
        } else {
          this.selectContentMenu(0)
        }
      }
      else {
        // this.$router.replace('/' + this.topMmenuList[index].name)
        this.route_replace(this.topMmenuList[index].name)
      }
    }, selectContentMenu: function (type) { //设置左边内容子菜单
      this.leftContentMenuType = this.subMenuList[type]['id']
      // this.$router.replace('/' + this.subMenuList[type].name)
      this.route_replace(this.subMenuList[type].name)
    }
  }
}
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #F3F4F8;
}

.main {
  background-color: #F3F4F8;
  display: flex;
}

.leftMain {
  width: 58px;
  background-color: white;
}

.leftMain img {
  width: 58px;
}

.rightMain {
  width: 100%;
  margin: 10px 10px;
}

.userView {
  text-align: center;
  margin: 10px auto;
  font-size: 10px;
  cursor: pointer;
}

.userView img {
  width: 34px;
  height: 34px;
  border-radius: 34px;
}

.topMenu {
  background-color: white;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  height: 60px;
  line-height: 60px;
  & > .tip {
    float: right;
    padding: 0;
    border: none;
    color: #595959;

    &:hover {
      cursor: pointer;
    }

    .warning {
      margin-left: 6px;
      color: #4F84FF;
      font-weight: bold;
      color: 22px;
      vertical-align: text-bottom;
    }
  }
}

.topMenu div {
  display: inline;
  margin: 0 30px;
  font-size: 16px;
  padding: 15px 0;
  border-bottom: 10px solid #6FADFF;
}

.topMenu .menuItemDiv {
  color: #403e3e;
  border-bottom: 5px solid white;
  cursor: pointer;
  font-weight: bold;
}

.topMenu .selectMenuItemDiv {
  color: #125dff;
  border-bottom: 3px solid #125dff;
  cursor: pointer;
  font-weight: bold;
}

.contentDiv {
  display: flex;
  margin: 10px 0;
  overflow-y: auto;
  background-color: white;
}

.contentDiv .content {
  width: 100%;
  /* background-color: white; */
}

/* .limit-width{width: 16rem !important;} */
.limit-width {
  min-width: calc(100% - 140px) !important;
}

/* .small-width{width: 18.6rem !important} */
.small-width {
  width: calc(100% - 140px) !important
}

.contentDiv .contentMenu {
  width: 140px;
  background-color: white;
  text-align: center;
  border-right: 1px #ECEDF2 solid;
}

.contentDiv .contentMenuItem {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 800;
  color: #595959;
}

.contentDiv .selectContentMenuItem {
  color: #2B6BFF;
  background-color: #F5F7FC;
}

.contentMenu img {
  width: 22px;
  height: 22px;
  position: relative;
  top: 6px;
  margin-right: 7px;
}

.watermark-box {
  position: fixed;
  top: 0;
  z-index: 9999;
  pointer-events: none;
  color: rgba(210, 210, 210, 0.2);
  width: 120%;
}

.watermark-box>span {
  transform: rotate(-15deg);
  display: inline-block;
  margin: 50px;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px !important;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px !important;
  cursor: pointer !important;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  background-color: #DDDEE0 !important;
  /* background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
  ) !important; */
}

::-webkit-scrollbar-thumb:hover {
  /*滚动条里面小方块*/
  border-radius: 10px !important;
  background-color: #b3b3b4 !important;
}

.main-left-menu {
  width: 58px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.main-left-menu.main-left-active {
  background-image: linear-gradient(to right, #e2e1f8, #fdfdff);
}

.main-left-menu-icon {
  font-size: 20px;
  color: #a5b0c6;
}

.main-left-menu-icon.main-left-active {
  color: #125cfb;
}

.main-left-menu-title {
  color: #454242;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
</style>

<style lang="scss" scoped>
.slide-bar-left {
  cursor: pointer;
  position: fixed;
  width: 140px;
  bottom: 39px;
  left: 56px;
  z-index: 1000;
}

#noHiddenElem {
  display: block;
}

#hiddenElem {
  display: none;
}

.el-table .cell {
  overflow: visible !important;
}
::v-deep .limit-width{
  overflow: scroll;
}

::v-deep .xt-tip-dialog {
  border-radius: 20px;

  .el-dialog__header {
    padding-bottom: 0;
    .el-dialog__title {
      color: #363636;
      font-weight: bold;
      font-size: 16px;
    }

    .el-dialog__headerbtn {
      .el-icon-close {
        font-size: 23px;
        color: #787878;
      }
    }
  }

  .el-dialog__body {
    padding-top: 0;
  }
}
.flip{
  transform: rotateY(180deg);
}
</style>
